<template>
    <div class="space-y-5">
        <div>
            <h2 class="text-lg font-medium text-gray-900 dark">
                Repartition des clients par officine
            </h2>
            <e-charts autoresize :options="config" ref="chart_client" theme="ovilia-green" auto-resize />
        </div>
        <div>
            <h2 class="text-lg font-medium text-gray-900 dark">
                Repartition des clients par civilités
            </h2>
            <e-charts autoresize :options="configGenre" ref="chart_client_pie" theme="ovilia-green" auto-resize />
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import ECharts from 'vue-echarts/components/ECharts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/pie'
import theme from './theme.json'

ECharts.registerTheme('ovilia-green', theme)

export default {
  name: 'EchartClient',
  components: {
    ECharts
  },
  data () {
    return {
      civilites: [
        {
          text: 'Prof',
          value: 'Prof'
        },
        {
          text: 'Dr',
          value: 'Dr'
        },
        {
          text: 'M',
          value: 'M'
        },
        {
          text: 'Mme',
          value: 'Mme'
        },
        {
          text: 'Mlle',
          value: 'Mlle'
        },
        {
          text: 'Enft',
          value: 'Enft'
        }
      ],
      config: null,
      configGenre: null,
      totalData: [],
      sum: 0,
      rawData: [
        [100, 302, 301],
        [320, 132, 101],
        [220, 182, 191],
        [150, 212, 201],
        [820, 832, 901],
        [820, 832, 901]
      ]
    }
  },
  computed: {
    ...mapGetters('statistique', ['getChartNombreClientData', 'getChartNombreGenreClientData']),
    ...mapGetters('officine', ['getOfficines'])
  },
  methods: {
    ...mapActions('statistique', ['nbreClientbyOfficines', 'nbreClientGenreByOfficines']),
    async fetchData () {
      await this.nbreClientbyOfficines()
      this.renderChart(this.getChartNombreClientData)
    },
    async fetchGenreData () {
      await this.nbreClientGenreByOfficines()
      this.renderChartGenre(this.getChartNombreGenreClientData)
    },
    renderChart (data) {
      const option = {
        legend: {
          type: 'plain',
          show: true
        },
        tooltip: {},
        // Declare X axis, which is a category axis, mapping
        // to the first column by default.
        xAxis: [
          { 
            type: 'category', 
            data: data.map(item => item.codeOfficine)
          }
        ],
        // Declare Y axis, which is a value axis.
        yAxis: [{type: 'value'}],
        // Declare several series, each of them mapped to a
        // column of the dataset by default.
        series: [
          { 
            name: 'Client(s)',
            type: 'bar',
            data: data.map(item => item.nbreClient),
            seriesLayoutBy: 'row'
          }
        ]
      }
      // Appliquez l'option au graphique
      this.config = option
      //console.log(this.config)
    },
    renderChartGenre (data) {
      //Créez une liste complète de toutes les combinaisons d'agences et de civilités.
      const allAgenceCivilities = []
      this.getOfficines.forEach(agence => {
        this.civilites.forEach(civilite => {
          allAgenceCivilities.push({
            idOfficine: agence._id,
            civilite: civilite.value,
            codeOfficine: agence.Code,
            nomOfficine: agence.NomOffic
          })
        })
      })

      const updatedState = []
      allAgenceCivilities.forEach(combination => {
        const existingEntry = data.find(entry => entry.idOfficine === combination.idOfficine && entry.civilite === combination.civilite)

        if (existingEntry) {
          updatedState.push(existingEntry)
        } else {
          updatedState.push({
            ...combination,
            nbreClient: 0 // Initialisez à 0 si la combinaison n'existe pas déjà
          })
        }
      })
      const groupedData = {}
      updatedState.forEach((client) => {
        const officineId = client.idOfficine

        if (!groupedData[officineId]) {
          groupedData[officineId] = []
        }

        groupedData[officineId].push(client)
      })

      const resultArray = Object.values(groupedData)

      const reversedData = {}

      resultArray.forEach(agenceData => {
        agenceData.forEach(entry => {
          const { civilite, nbreClient, idOfficine, codeOfficine, nomOfficine } = entry

          if (!reversedData[civilite]) {
            reversedData[civilite] = []
          }

          reversedData[civilite].push({
            idOfficine,
            nbreClient: nbreClient || 0,
            codeOfficine,
            nomOfficine
          })
        })
      })

      const codes = resultArray.map(item => item[0].codeOfficine)

      const row = Object.entries(reversedData).map(([civilite, data]) => ({
        [civilite]: data
      }))

      for (let index = 0; index < codes.length; index++) {
        this.sum = 0
        for (const civility of ['Prof', 'Dr', 'M', 'Mme', 'Mlle', 'Enft']) {
          for (let j = 0; j < row.length; j++) {
            const element = row[j][civility]

            if (element) {
              for (let i = 0; i < element.length; i++) {
                const elt = element[i]
                if (elt.codeOfficine === codes[index]) {
                  this.sum += elt.nbreClient
                }
              }
            }
          }
        }
        this.totalData.push(this.sum)
      }

      const formatedRow = []

      row.map((item) => {
        for (const civility of ['Prof', 'Dr', 'M', 'Mme', 'Mlle', 'Enft']) {
          if (item[civility]) {
            const tempArray = []
            for (let i = 0; i < item[civility].length; i++) {
              const element = item[civility][i]
              tempArray.push(element.nbreClient)
            }
            formatedRow.push(tempArray)
          }
          
        }
      })

      this.rawData = formatedRow
      // console.log(this.rawData)
      const series = this.civilites.map((civilite, sid) => {
        return {
          name: civilite.text,
          type: 'bar',
          stack: 'total',
          barWidth: '60%',
          label: {
            show: true,
            formatter: (params) => `${Math.round(params.value * 1000) / 10  }%`
          },
          data: this.rawData[sid].map((d, did) => {
            return this.totalData[did] <= 0 ? 0 : d / this.totalData[did]
          })
  
        }
      })
      const option = {
        // title: {
        //   text: 'Referer of a Website',
        //   subtext: 'Fake Data',
        //   left: 'center'
        // },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          data: this.civilites.map(civilite => civilite.text),
          selectedMode: true
        },
        yAxis: {
          type: 'value'
        },
        xAxis: {
          type: 'category',
          data: codes
        },
        series
      }

      this.configGenre = option
    }
  },
  mounted () {
    this.fetchData()
    this.fetchGenreData()
  },
  beforeMount () {
    
  },
  created () {
  }
}
</script>